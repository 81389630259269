<template>
    <section class="contact-section">
        <div class="card">
            <form class="form">
                <div>
                    <span>Nom:</span>
                    <br />
                    <input v-model="form.firstname" placeholder="Nom" />
                </div>
                <div>
                    <span>Prénom:</span>
                    <br />
                    <input v-model="form.lastname" placeholder="Nom" />
                </div>
                <div class="fullwidth">
                    <span>Email:</span>
                    <br />
                    <input v-model="form.email" placeholder="Email" />
                </div>
                <div class="fullwidth">
                    <span>Message:</span>
                    <br />
                    <textarea v-model="form.message" class="textarea">
                    </textarea>
                </div>
                <div class="fullwidth">
                    <button @click="sendMessage" type="button">
                        Envoyer le message
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
const isValidEmail = require("is-valid-email");
import Vue from "vue";

export default {
    data() {
        return {
            form: {
                firstname: "",
                lastname: "",
                message: "",
                email: "",
            },
        };
    },

    methods: {
        sendMessage() {
            if (!isValidEmail(this.form.email)) {
                Vue.notify({
                    group: "toast",
                    type: "error",
                    title: "Email invalide",
                    closeOnClick: true,
                });
            } else if (
                !this.form.firstname ||
                !this.form.lastname ||
                !this.form.message
            ) {
                Vue.notify({
                    group: "toast",
                    type: "error",
                    title: "Aucun champ ne doit être laissé vide",
                    closeOnClick: true,
                });
            }
            fetch(this.$API + "/mailer/contact", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    firstName: this.form.firstname,
                    lastName: this.form.lastname,
                    email: this.form.email,
                    message: this.form.message,
                }),
            })
                .then(() => {
                    Vue.notify({
                        group: "toast",
                        type: "success",
                        title:
                            "Message envoyé, nous vous recontacterons dès que possible",
                        closeOnClick: true,
                    });

                    this.form = {
                        firstname: "",
                        lastname: "",
                        message: "",
                        email: "",
                    };
                })
                .cath(() => {
                    Vue.notify({
                        group: "toast",
                        type: "error",
                        title:
                            "Une erreur inattendue s’est produite.",
                        closeOnClick: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.contact-section {
    padding-top: 100px;
    letter-spacing: 0ch;
}
.card {
    margin: 1rem;
    background-color: white;
    border-radius: 20px;
}
.form {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px;
    align-items: center;
    justify-items: center;
}
.form * {
    margin: 5px;
    width: 90%;
}

.textarea {
    height: 100px;
}

@media (min-width: 500px) {
    .form {
        grid-template-columns: repeat(2, 1fr);
        padding: 25px;
    }
    .fullwidth {
        grid-column: 1 / 3;
    }

    .form * {
        width: 100%;
    }

  .card {
    margin: 2rem 25%;
  }
}
</style>
